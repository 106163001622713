<template>
  <div>
    <v-row>
      <v-card min-height="600px">
        <v-card-title><v-card-text>About</v-card-text></v-card-title>
        <v-divider class="my-4 mx-10"></v-divider>
        <v-row
          v-for="subheading in subheadings"
          :key="subheading.title"
          class="mx-10 primary--text text--darken-2 text-h4"
        >
          <v-card-subtitle v-text="subheading.title"></v-card-subtitle>
          <v-card-text class="text-h6 font-weight-light" v-text="subheading.text"></v-card-text>
          <v-divider class="my-4 mx-10"></v-divider>
        </v-row>
      </v-card>
    </v-row>
  </div>
</template>
<script>
// @ is an alias to /src

export default {
  // name: 'App',
  components: {},
  data: () => ({
    subheadings: [
      {
        color: '#f1fafa',
        img: '',
        title: 'Founders',
        text:
          'ElasticGreen was founded by Keith Morgan and Robby Lopez to bring innovation to hydroponics, '
          + 'especially for the home user.',
      },
      {
        color: '#f1fafa',
        img: '',
        title: 'Forum',
        text: 'Coming soon.',
      },
    ],
  }),
};
</script>
